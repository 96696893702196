'use client'
import { useMutation, useQuery } from '@apollo/client'
import { useRouter } from 'next/navigation'
import { useContext, useState } from 'react'
import { isNil } from 'ramda'
import { useLDClient } from 'launchdarkly-react-client-sdk'

import { isRadiusUser } from '~/helpers/auth'

import {
  GetAuthenticatedUserQuery,
  GetAuthenticatedUserQueryVariables,
  LogoutAuthenticatedUserMutation,
  LogoutAuthenticatedUserMutationVariables,
} from '~/hooks/useAuthentication/__generated_types__/queries.gql'
import { GET_AUTHENTICATED_USER, LOGOUT } from '~/hooks/useAuthentication/queries'
import { AuthenticatedUser, User } from '~/hooks/useAuthentication/types'

import { setUser } from '~/clients/sentryClient'
import {
  identifyUser,
  MixpanelEvent,
  register,
  resetMixpanel,
  track,
} from '~/clients/mixpanelClient'
import { AuthenticatedUserContext } from '~/providers/AuthenticatedUserContext'
import { Urls } from '~/constants/urls'

export const useAuthentication = () => {
  const router = useRouter()

  const [authenticatedUser, setAuthenticatedUser] = useState<User>(null)

  const ldClient = useLDClient()

  const { loading, refetch } = useQuery<
    GetAuthenticatedUserQuery,
    GetAuthenticatedUserQueryVariables
  >(GET_AUTHENTICATED_USER, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data.currentUser) return
      const email = data.currentUser.emails?.[0]?.email || ''
      const role = data.currentUser.role
      setUser(`${data.currentUser.uuid}`, email)
      identifyUser(`${data.currentUser.uuid}`)
      register({ email, role })

      ldClient?.identify({
        kind: 'user',
        name: data.currentUser.fullname || undefined,
        key: email,
        email,
        custom: {
          role,
        },
      })
      setAuthenticatedUser(data.currentUser)
    },
    onError: () => {
      router.push('/login')
    },
  })

  const [logout] = useMutation<
    LogoutAuthenticatedUserMutation,
    LogoutAuthenticatedUserMutationVariables
  >(LOGOUT, {
    onCompleted: (data) => {
      track(MixpanelEvent.UserLogout, { userId: data.logout.id })
      resetMixpanel()
      ldClient?.identify({
        kind: 'user',
        anonymous: true,
      })
      if (isRadiusUser(data.logout)) {
        router.push(Urls.login())
      } else {
        router.push(Urls.rydershare())
      }
    },
    onError: () => {
      router.push(Urls.login())
    },
  })

  return [authenticatedUser, logout, { loading, refetch }] as const
}

export const useAuthenticatedUser = (): AuthenticatedUser => {
  const authentication = useContext(AuthenticatedUserContext)
  const router = useRouter()

  const [user, logout, options] = authentication

  if (isNil(user) || isNil(logout) || isNil(options)) {
    router.push('/login')
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return [user!, logout!, options!] as const
}
