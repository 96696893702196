import { LoadDetailViewTabs } from '~/src/components/RyderShare/CustomerVisibility/DetailView/Tabs'

type LocationUrlProps = {
  locationId: bigint
}

type UrlProps = {
  id: number
}

export const OperatorLoadBasePath = '/loadboard'
export const CustomerLoadBasePath = '/rydershare/visibility'
export const ShipmentBasePath = '/shipments'
export const RydershareBasePath = 'https://www.rydershare.com'

const Urls = {
  location: () => '/?tab=location',
  locationById: ({ locationId }: LocationUrlProps) =>
    `${Urls.location()}&id=${locationId}`,
  login: () => '/login',
  operatorLoad: ({ id }: UrlProps) => `${OperatorLoadBasePath}/${id}`,
  customerLoad: ({ id, tab = 'map' }: UrlProps & { tab?: LoadDetailViewTabs }) =>
    `${CustomerLoadBasePath}/${id}?tab=${tab}`,
  shipment: ({ id }: UrlProps) => `${ShipmentBasePath}/${id}`,
  shipmentLoad: ({
    id,
    loadId,
    tab = 'map',
  }: UrlProps & {
    loadId: number
    tab: LoadDetailViewTabs
  }) => `${ShipmentBasePath}/${id}/load/${loadId}?tab=${tab}`,
  rydershare: () => `${RydershareBasePath}/landing`,
}

export { Urls }
